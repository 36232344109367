<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-pen"></i>  แก้ไขข้อมูลการการจัดส่ง</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(saveHandler)">
              <form-delivery :item="item" :fileBefores="fileBefores" :fileAfters="fileAfters" :deletes="deletes" />
              <div class="float-right d-flex">
                <span><router-link :to="'/delivery'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button type="submit" variant="primary" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from '../../../util/config';
import DateUtils from '../../../util/dateUtils';
import StringUtils from '../../../util/StringUtils';
import formDelivery from './form.delivery';
import Swal from 'sweetalert2/dist/sweetalert2';
export default {
  name: 'delivery-view-page-delivery-edit',
  data () {
    return {
      permission:{
        appSlug: 'delivery',
        actionData: {}
      },
      item: {
        order_comment : "",
        photo_before_encode: [],
        photo_after_encode: []
      },
      fileBefores: [],
      fileAfters: [],
      deletes: [],
      id: ''
    }
  },
  methods: {
    async saveHandler () {
      let params = {
        actual_delivery_date: DateUtils.dateFormat(this.item.actual_delivery_date, "YYYY-MM-DD"),
        actual_delivery_time: DateUtils.dateFormat(this.item.actual_delivery_time, "HH:mm"),
        comment: this.item.comment,
        remark: this.item.remark,
        user_id: this.item.user_id,
        status: this.item.status
      };
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.putData(`/ab_delivery/${this.item.id}`, params);
      if(result&&result.status.code=="200"){
        
        const params2 = { comment: this.item.order_comment};
        const result2 = await this.HttpServices.putData(`/ab_order/save_comment/${this.item.order_id}`, params2);
        
        /*
        if (result2 && result2.status.code == "200") {
          console.log("saved");
        }
        */


        const s3Auth = await this.HttpServices.authenS3();
        if(s3Auth._info){
          let medias = [];
          const accessToken = s3Auth._info.secret.accessToken;
          if(this.fileBefores&&this.fileBefores.length>0){
            medias = [];
            for(const v of this.fileBefores){
              if(v instanceof File){
                params = new FormData();
                params.append("file", v);
                params.append("title", v.name);
                params.append("file_category", 'order-delivery');
                const resultMedia = await this.HttpServices.postFormDataS3(params, accessToken);
                if(resultMedia.media&&resultMedia.media.length>0){
                  const media = resultMedia.media[0];
                  const mediaUpload = {
                    id: media.id,
                    original_name: media.original_name,
                    mime_type: media.mime_type,
                    size: media.size,
                    url: media.url
                  };

                  params = new FormData();
                  const thumbFile = await StringUtils.resizeBlobToFile({file: v, maxSize: 200});
                  params.append("file", thumbFile);
                  params.append("title", thumbFile.name);
                  params.append("file_category", 'order-delivery');
                  let resultThumb = await this.HttpServices.postFormDataS3(params, accessToken);
                  if(resultThumb.media&&resultThumb.media.length>0){
                    const mediaThumb = resultThumb.media[0];
                    mediaUpload.id_t = mediaThumb.id;
                    mediaUpload.original_name_t = mediaThumb.original_name;
                    mediaUpload.mime_type_t = mediaThumb.mime_type;
                    mediaUpload.size_t = mediaThumb.size;
                    mediaUpload.url_t = mediaThumb.url;
                  }
                  medias.push(mediaUpload);
                }
              }else{
                const mediaUpload = {
                  id: v.id,
                  original_name: v.original_name,
                  mime_type: v.mime_type,
                  size: v.size,
                  url: v.url
                };
                if(v.id_t){
                    mediaUpload.id_t = v.id_t;
                    mediaUpload.original_name_t = v.original_name_t;
                    mediaUpload.mime_type_t = v.mime_type_t;
                    mediaUpload.size_t = v.size_t;
                    mediaUpload.url_t = v.url_t;
                }
                medias.push(mediaUpload);
              }

              // const params = new FormData();
              // params.append("fileUpload", v);
              // await this.HttpServices.putFormData(`/ab_delivery/uploadPhotoBefore/${this.item.id}`, params);
            }
            if(medias){
              await this.HttpServices.putFormData(`/ab_delivery/uploadPhotoBeforeNoTimeStamp/${this.item.id}`, {media: JSON.stringify(medias)});
            }
          }
          if(this.fileAfters&&this.fileAfters.length>0){
            medias = [];
            for(const v of this.fileAfters){
              if(v instanceof File){
                params = new FormData();
                params.append("file", v);
                params.append("title", v.name);
                params.append("file_category", 'order-delivery');
                const resultMedia = await this.HttpServices.postFormDataS3(params, accessToken);
                if(resultMedia.media&&resultMedia.media.length>0){
                  const media = resultMedia.media[0];
                  const mediaUpload = {
                    id: media.id,
                    original_name: media.original_name,
                    mime_type: media.mime_type,
                    size: media.size,
                    url: media.url
                  };

                  params = new FormData();
                  const thumbFile = await StringUtils.resizeBlobToFile({file: v, maxSize: 200});
                  params.append("file", thumbFile);
                  params.append("title", thumbFile.name);
                  params.append("file_category", 'order-delivery');
                  let resultThumb = await this.HttpServices.postFormDataS3(params, accessToken);
                  if(resultThumb.media&&resultThumb.media.length>0){
                    const mediaThumb = resultThumb.media[0];
                    mediaUpload.id_t = mediaThumb.id;
                    mediaUpload.original_name_t = mediaThumb.original_name;
                    mediaUpload.mime_type_t = mediaThumb.mime_type;
                    mediaUpload.size_t = mediaThumb.size;
                    mediaUpload.url_t = mediaThumb.url;
                  }
                  medias.push(mediaUpload);
                }
              }else{
                const mediaUpload = {
                  id: v.id,
                  original_name: v.original_name,
                  mime_type: v.mime_type,
                  size: v.size,
                  url: v.url
                };
                if(v.id_t){
                    mediaUpload.id_t = v.id_t;
                    mediaUpload.original_name_t = v.original_name_t;
                    mediaUpload.mime_type_t = v.mime_type_t;
                    mediaUpload.size_t = v.size_t;
                    mediaUpload.url_t = v.url_t;
                }
                medias.push(mediaUpload);
              }
              // const params = new FormData();
              // params.append("fileUpload", v);
              // await this.HttpServices.putFormData(`/ab_delivery/uploadPhotoAfter/${this.item.id}`, params);
            }
            if(medias){
              await this.HttpServices.putFormData(`/ab_delivery/uploadPhotoAfterNoTimeStamp/${this.item.id}`, {media: JSON.stringify(medias)});
            }
          }
          if(this.deletes&&this.deletes.length>0){
            for(const v of this.deletes){
              await this.HttpServices.deleteDataS3(v, accessToken);
            }
          }
        }
          Swal.fire({
              title: "",
              html: `บันทึกสำเร็จ <br><small>*เนื่องจากมีการปรับปรุงระบบใหม่ โปรดกดปุ่มกลับ เพื่อกลับหน้าแรก</small>`,
              icon: "success",
              showCancelButton: false,
              "confirmButtonClass": "btn btn-primary",
              "confirmButtonText": 'ตกลง'
          }).then((result) => {
            window.location.reload();
          });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
      this.SpinnerService.hideLoader();
    },
    onBackHandler(){
      this.$router.push("/delivery");
    },

    async getItem(){
      const result = await this.HttpServices.getData(`/ab_delivery/${this.id}`);
      if(result&&result.status.code=="200"){
        this.item = result.data;
        const rst_comment = await this.HttpServices.getData(`/ab_order/load_comment/${this.item.order_id}`);
        if (rst_comment && rst_comment.status.code == "200") {
          this.item.order_comment = rst_comment.data.comment;
        }

        if(this.item.photo_before){
          // const photoEncode = [];
          // for(const vv of this.item.photo_before_encode){
          //   const img = `${vv}`;
          //   photoEncode.push(img);
          // }
          // this.item.photo_before_encode = photoEncode;
          this.fileBefores = JSON.parse(JSON.stringify(this.item.photo_before_encode));
        }
        if(this.item.photo_after){
          // const photoEncode = [];
          // for(const vv of this.item.photo_after_encode){
          //   const img = `${vv}`;
          //   photoEncode.push(img);
          // }
          // this.item.photo_after_encode = photoEncode;
          this.fileAfters = JSON.parse(JSON.stringify(this.item.photo_after_encode));
        }

        if(this.item.actual_delivery_date){
          this.item.actual_delivery_date = new Date(this.item.actual_delivery_date);
        }
        if(this.item.actual_delivery_time){
          const tmps = this.item.actual_delivery_time.split(":");
          const d = new Date(this.item.actual_delivery_date);
          d.setHours(tmps[0]);
          d.setMinutes(tmps[1]);
          this.item.actual_delivery_time = d;
        }

        

      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลการจัดส่ง`, ()=>{
          this.$router.push('/delivery');
        });
      }
    },
  },
  components: {
    formDelivery
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-delivery");
    this.getItem();
  },
}
</script>
