<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลการจัดส่ง</h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="mb-4">
                <label class="form-label">รหัสการสั่งซื้อ</label>
                <base-input name="order_code" placeholder="รหัสการสั่งซื้อ" v-model="item.order_code" disabled></base-input>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="mb-4">
                <label class="form-label">สถานะการจัดส่ง</label>
                <select class="form-control"
                        v-model="item.status">
                  <option v-for="(data) in status_delivery"
                          :key="data.id"
                          :value="data.id">
                    {{data.text}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="mb-4">
                <label class="form-label">วันที่จัดส่งที่กำหนด</label>
                <base-input name="expected_delivery_date" placeholder="วันที่จัดส่งที่กำหนด"
                            v-model="item.expected_delivery_date_short" disabled />
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="mb-4">
                <label class="form-label">เวลาจัดส่งที่กำหนด</label>
                <base-input name="expected_delivery_date" placeholder="เวลาจัดส่งที่กำหนด"
                            v-model="item.expected_delivery_time_short" disabled />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <label class="form-label">วันที่จัดส่งจริง</label>
                <date-picker  :format="'DD/MM/YYYY'"
                              v-model="item.actual_delivery_date"
                              class="w-100"
                              input-class="form-control"
                              placeholder="วันที่จัดส่งจริง"
                              :append-to-body="false"
                              :popup-style="{left: 0}">
                </date-picker>
                <base-input name="actual_delivery_date" v-model="actual_date" :rules="{required: true}" class="custom-input-valid-datepicker"></base-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <label class="form-label">เวลาจัดส่งจริง</label>
                <date-picker  :format="'HH:mm'"
                              v-model="item.actual_delivery_time"
                              class="w-100"
                              input-class="form-control"
                              placeholder="เวลาจัดส่งจริง"
                              :append-to-body="false"
                              :popup-style="{left: 0}"
                              type="time">
                </date-picker>
                <base-input name="actual_delivery_time" v-model="actual_time" :rules="{required: true}" class="custom-input-valid-datepicker"></base-input>
              </div>
            </div>
            <div class="mb-4 col-12">
             <label class="form-label">ผู้ดำเนินจัดส่ง</label>
                <!--
             <select class="form-control" v-model="item.user_id">
                  <option v-for="option in cbbOptions.user" :key="`cbx_user_${option.id}`" :value="option.id" >{{ option.text }}</option>
                </select>
                -->
                <Select2 v-model="item.user_id" :options="cbbOptions.user" :settings="{allowClear: true, multiple: false}" placeholder="User"/>
             
              </div>
            </div>
            <div class="col-12">
              <hr class="my-4">
            </div>
            <div class="col-12">
              <h6 class="heading-small text-muted mb-4">อัพโหลดภาพ</h6>
              <div class="row">
                <div class="col-12">
                  <div class="mb-4">
                    <label class="form-label">ก่อนส่ง</label>
                    <div class="card">
                      <div class="card-body m-auto" >
                        <input type="file" id="fileUpload" accept="image/*" style="display: none" @change="uploadFileChangeHandler" multiple/>
                        <div v-for="(img, index) in item.photo_before_encode"
                                :key="`list_photo_before_${index}`" class="position-relative float-left">
                          <i class="fa fa-times text-danger position-absolute" style="right: 3px; top: 3px;" @click="deleteImageBeforeHandler(img, index)"></i>
                          <img :src="img.url" class="img-thumbnail" style="max-width: 180px;" @click="uploadFileHandler()"/>
                        </div>
                        <img v-if="item.photo_before_encode.length==0" :src="noImageData" class="img-thumbnail" style="max-width: 180px;" @click="uploadFileHandler()">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-4">
                    <label class="form-label">หลังส่ง</label>
                    <div class="card">
                      <div class="card-body m-auto">
                        <input type="file" id="fileUploadAfter" accept="image/*" style="display: none" @change="uploadFileChangeHandlerAfter" multiple/>
                        <div v-for="(img, index) in item.photo_after_encode"
                                :key="`list_photo_after_${index}`" class="position-relative float-left">
                          <i class="fa fa-times text-danger position-absolute" style="right: 3px; top: 3px;" @click="deleteImageAfterHandler(img, index)"></i>
                          <img :src="img.url" class="img-thumbnail" style="max-width: 180px;" @click="uploadFileHandlerAfter()"/>
                        </div>
                        <img v-if="item.photo_after_encode.length==0" :src="noImageData" class="img-thumbnail" style="max-width: 180px;" @click="uploadFileHandlerAfter()"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <hr class="my-4">
            </div>
            <div class="col-12">
              <h6 class="heading-small text-muted mb-4">เพิ่มเติม</h6>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-4">
                    <label class=" col-form-label optional">ความคิดเห็น</label>
                    <div class="">
                      <b-form-textarea name="comment" placeholder="ความคิดเห็น" v-model="item.comment" rows="3" max-rows="5"></b-form-textarea>
                      <!-- <base-input name="comment" v-model="item.comment" :rules="{required: true}" class="custom-input-valid-datepicker"></base-input> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-4">
                    <label class=" col-form-label optional">หมายเหตุ</label>
                    <div class="">
                      <b-form-textarea name="remark" placeholder="หมายเหตุ" v-model="item.remark" rows="3" max-rows="5"></b-form-textarea>
                      <!-- <base-input name="remark" v-model="item.remark" :rules="{required: true}" class="custom-input-valid-datepicker"></base-input> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-4">
                    <label class=" col-form-label optional">CS Comment (From Order)</label>
                    <div class="">
                      <b-form-textarea name="order_comment" placeholder="ความคิดเห็น" v-model="item.order_comment" rows="3" max-rows="5"></b-form-textarea>
                      <!-- <base-input name="comment" v-model="item.comment" :rules="{required: true}" class="custom-input-valid-datepicker"></base-input> -->
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-4">
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import Constants from '../../../util/constants';
export default {
  name: 'delivery-form-delivery',
  data () {
    return {
      cbbOptions:{
        users : []
      },
      noImageData: Constants.noImageData,
      status_delivery: Constants.deliveries_status,
      actual_date: '',
      actual_time: ''
    }
  },
  props: [
    'item', 'fileBefores', 'fileAfters', 'deletes'
  ],
  methods: {
    deleteImageBeforeHandler(img, index){
      this.item.photo_before_encode.splice(index, 1);
      this.fileBefores.splice(index, 1);
      if(img.id){
        this.deletes.push(img.id);
        if(img.id_t){
          this.deletes.push(img.id_t);
        }
      }
    },
    deleteImageAfterHandler(img, index){
      this.item.photo_after_encode.splice(index, 1);
      this.fileAfters.splice(index, 1);
      if(img.id){
        this.deletes.push(img.id);
        if(img.id_t){
          this.deletes.push(img.id_t);
        }
      }
    },
    uploadFileHandler(){
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler (event) {
      let img = event.target.files;
      if (img.length > 0) {
        const vm = this;
        for (let i = 0; i < img.length; i++) {
          var reader = new FileReader();
          reader.onload = function(event) {
            vm.item.photo_before_encode.push({url:event.target.result});
          }
          reader.readAsDataURL(img[i]);
          vm.fileBefores.push(img[i]);
        }
      }
    },
    uploadFileHandlerAfter(){
      $("#fileUploadAfter").trigger("click");
    },
    uploadFileChangeHandlerAfter (event) {
       let img = event.target.files
      if (img.length > 0) {
        const vm = this;
        for (let i = 0; i < img.length; i++) {
          var reader = new FileReader();
          reader.onload = function(event) {
            vm.item.photo_after_encode.push({url:event.target.result});
          }
          reader.readAsDataURL(img[i]);
          vm.fileAfters.push(img[i]);
        }
      }
    },
    async initData() {
      this.cbbOptions.user = await this.HttpServices.getMasterData("/master/getUsers");
    },
  },
  
  async mounted() {
    await this.initData();
  },
  watch: {
    'item.actual_delivery_date'(newVal){
      if(newVal){
        this.actual_date = ""+newVal;
      }else{
        this.actual_date = "";
      }
    },
    'item.actual_delivery_time'(newVal){
      if(newVal){
        this.actual_time = ""+newVal;
      }else{
        this.actual_time = "";
      }
    }
  }
}
</script>
